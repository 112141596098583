<template lang="pug">
.feedback-question
  .feedback-question-description
    span(v-if="isOrderRating")
      | O que você achou da&#32;
      b sua box como um todo
      | ?

    span(v-else-if="isProductRating")
      | O que você achou do produto&#32;
      b {{ question.product?.name }}
      | &#32;da&#32;
      b {{ question.product?.brandName }}
      | ?

  .feedback-answer-rating
    .feedback-rating
      Rating(v-model="ratingAnswer")
    .feedback-rating-description
      | {{ ratingDescription }}

  textarea.feedback-answer-text(
    :rows="6",
    :maxlength="4096",
    v-model="textAnswer",
    placeholder="Comentários adicionais (opcional)"
  )
</template>
<script setup lang="ts">
import type { PropType } from "vue"

import type {
  FeedbackQuestion,
  FeedbackAnswer,
} from "./FeedbackData"

import { FeedbackQuestionType } from "./FeedbackData"

const props = defineProps({
  question: {
    type: Object as PropType<FeedbackQuestion>,
    required: true,
  },
  modelValue: {
    type: Object as PropType<FeedbackAnswer>,
  },
})

const emit = defineEmits(["update:modelValue"])

const isOrderRating = computed(
  () => props.question.type == FeedbackQuestionType.ORDER_FEEDBACK
);
const isProductRating = computed(
  () => props.question.type == FeedbackQuestionType.PRODUCT_FEEDBACK
);

const ratingAnswer = computed<number | undefined>({
  get() {
    return props.modelValue?.score;
  },
  set(value) {
    emit("update:modelValue", {
      type: props.question.type,
      productId: props.question.product?.id,
      score: value,
      feedback: props.modelValue?.feedback,
    });
  },
});

const ratingDescription = computed(() => {
  switch (ratingAnswer.value) {
    case 1:
      return "Não gostei nem um pouco";
    case 2:
      return "Não gostei";
    case 3:
      return "Indiferente";
    case 4:
      return "Gostei";
    case 5:
      return "Amei muito!";
  }
});

const textAnswer = computed({
  get() {
    return props.modelValue?.feedback;
  },
  set(value) {
    emit("update:modelValue", {
      type: props.question.type,
      productId: props.question.product?.id,
      score: props.modelValue?.score,
      feedback: value ? value : null,
    });
  },
});
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables'

.feedback-question

  .feedback-answer-rating
    padding: 32px 16px

    .feedback-rating
      margin-bottom: 4px
      text-align: center
      font-size: 24px

    .feedback-rating-description
      height: 24px
      font-size: 18px
      font-weight: bold
      text-align: center
      color: orange

  textarea.feedback-answer-text
    width: 100%
    resize: none
    padding: 8px
    font-family: $primary-font-family
    font-size: 16px
    border-radius: 8px
    border: 1px solid $inactiveTextColor
    outline: none

    &:focus
      border-radius: 8px
      border-color: $textColor
</style>