const LOCAL_STORAGE_GOOGLE_REVIEW = "mgt_gr_lafra";

interface GoogleReviewStoreState {
  lastAskedForReviewAt: Date | null
}

export const useGoogleReview = defineStore('googleReview', () => {
  const localStorageLastAskedForReviewAt = useLocalStorage<string>(LOCAL_STORAGE_GOOGLE_REVIEW, null)

  const lastAskedForReviewAt = computed<Date | null>(() => {
    const lastAskedForReviewAtString = localStorageLastAskedForReviewAt.value

    try {
      if (lastAskedForReviewAtString != null) {
        return new Date(parseInt(lastAskedForReviewAtString))
      }
    } catch (e) {
      console.error(e)
    }

    return null
  })

  const markAskedForReview = () => {
    localStorageLastAskedForReviewAt.value = new Date().getTime().toString()
  }

  return {
    lastAskedForReviewAt,
    markAskedForReview
  }
})