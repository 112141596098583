import type { FetchError } from 'ofetch'
import type {
  SubscriptionBoxOrder,
  SubscriptionBoxProductFeedback,
  SubscriptionBoxProduct,
} from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'

import SubscriptionBoxOrderService from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'

async function sendFeedback(orderId: number, answers: FeedbackAnswer[]): Promise<void> {
  const productFeedbacks =
    answers
      .filter((answer) => answer.type == FeedbackQuestionType.PRODUCT_FEEDBACK)
      .map((answer) => ({ productId: answer.productId!, score: answer.score, feedback: answer.feedback ?? undefined } as SubscriptionBoxProductFeedback))

  const orderAnswer =
    answers
      .filter((answer) => answer.type == FeedbackQuestionType.ORDER_FEEDBACK)
      .pop()

  if (!orderAnswer) {
    throw "Nenhuma avaliação sobre a box foi dada"
  }

  return SubscriptionBoxOrderService.registerFeedback(orderId, {
    orderScore: orderAnswer.score,
    orderFeedback: orderAnswer.feedback ?? undefined,
    additionalAnswers: [],
    productFeedbacks,
  })
}

export function useFeedbackData() {
  const route = useRoute()
  const notifications = useNotifications()
  const loaded = ref<boolean>(false)
  const started = ref<boolean>(false)
  const sent = ref<boolean>(false)
  const sending = ref<boolean>(false)
  const orderId = ref<number | null>(null)
  const order = ref<SubscriptionBoxOrder | null>(null)
  const products = ref<SubscriptionBoxProduct[] | null>(null)
  const answers = ref<FeedbackAnswer[]>([])

  onMounted(() => {
    orderId.value = parseInt(route.params["orderId"] as string)

    Promise.all([
      SubscriptionBoxOrderService.findOrder(orderId.value),
      SubscriptionBoxOrderService.findProducts(orderId.value)
        .catch((error: FetchError) => {
          if (error.status == 404) {
            return null
          } else {
            throw error
          }
        }),
    ])
      .then((values) => {
        if (values[0] !== null && values[1] !== null) {
          order.value = values[0]
          products.value = values[1]
          loaded.value = true
        } else {
          throw `Falha ao carregar informações da box`
        }
      })
      .catch((error) => {
        captureException(new Error('Failed to load feedback data', { cause: error}))

        notifications.error(`Falha ao carregar informações da box`)
      })
  })

  const questions = computed<FeedbackQuestion[] | null>(() => {
    if (products.value) {
      const questions = products.value
        .map((product) => ({ type: FeedbackQuestionType.PRODUCT_FEEDBACK, product } as FeedbackQuestion))

      questions.push({ type: FeedbackQuestionType.ORDER_FEEDBACK, product: null })

      return questions
    } else {
      return null
    }
  })

  const send = async () => {
    const currentOrderId = orderId.value

    if (currentOrderId) {
      sending.value = true
      await sendFeedback(currentOrderId, answers.value)

      sending.value = false
      sent.value = true
    } else {
      throw "Failed to send feedback data"
    }
  }

  const orderScore = computed<number | null>(() => {
    const orderAnswer =
      (answers.value.filter((answer) => answer.type == FeedbackQuestionType.ORDER_FEEDBACK))[0] ?? null

    return orderAnswer?.score
  })

  return {
    loaded,
    started,
    sending,
    sent,
    orderId,
    order,
    products,
    questions,
    answers,
    send,
    orderScore
  }
}

export enum FeedbackQuestionType {
  ORDER_FEEDBACK,
  PRODUCT_FEEDBACK
}

export interface FeedbackQuestion {
  type: FeedbackQuestionType
  product: SubscriptionBoxProduct | null
}

export interface FeedbackAnswer {
  type: FeedbackQuestionType,
  productId: number | null,
  score: number,
  feedback?: string
}