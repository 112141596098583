import type { Ref } from "vue"

import { useGoogleReview } from '~/store/googleReview'

export function useGoogleReviewData(orderScore: Ref<number | null>) {
  const googleReview = useGoogleReview()
  const googleReviewAsked = googleReview.lastAskedForReviewAt != null

  const askForGoogleReview = computed(() => {
    const sample = Math.random() < 0.1

    if (sample && !googleReviewAsked && orderScore.value != null && orderScore.value >= 4) {
      return true
    } else {
      return false
    }
  })
  const markGoogleReviewAsked = () => {
    googleReview.markAskedForReview()
  }

  return {
    askForGoogleReview,
    markGoogleReviewAsked
  }
}